import React from "react";
// import errorIcon from "../../../assets/images/error-2.png";

const UrlFiltering = () => {
  return (
    <div className="tab-form">
      <div className="row mb-4">
        <div className="col-lg-4">
          <div className="switch-box">
            <strong>Blocked list</strong>
            <label className="ice-switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="domain-wrapper">
        <div className="domain-list">
          <h3>www.mydomain.com</h3>
          <button>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div className="domain-list">
          <h3>www.mydomain.com</h3>
          <button>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div className="domain-list">
          <h3>www.mydomain.com</h3>
          <button>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div className="domain-list">
          <h3>www.mydomain.com</h3>
          <button>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div className="domain-list">
          <h3>www.mydomain.com</h3>
          <button>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      {/* <div className="customModal">
        <div className="c-modal-dialog modal-dialog-centered small">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Domain</h5>
              <button type="button" className="btn-close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Add Domain</label>
                <input type="text" className="form-control" placeholder="www.example.com"/>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline">Cancel</button>
              <button type="submit" className="text-btn primary-btn">Save</button>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="customModal confirm-modal">
        <div className="modal-dialog modal-dialog-centered small">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure you want to delete?</p>
              <h3>www.mydomain.com</h3>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline">Cancel</button>
                <button className="text-btn primary-btn">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default UrlFiltering;
