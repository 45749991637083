import React from "react";
import Auto from "../../../../assets/images/Auto.png";
import Auth from "../../../../assets/images/Auth.png";
import Unauth from "../../../../assets/images/Unauth.png";

function radiusConfiguration() {
  return (
    <div>
      <div className="tab-form">
        <div className="row mb-4">
          <div className="col-lg-6">
            <div className="switch-box">
              <strong className="radius-help">
                RADIUS server settings are shown here.
                <span data-bs-toggle="modal" data-bs-target="#radiusHelp"><i className="fa fa-question-circle-o" aria-hidden="true"></i></span>
              </strong>              
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="radius-device">
            <div className="radius-device-title">
              <h3>Noida - 12 Port</h3>
              {/* <a href="#">Device Configuration <i class="fa fa-external-link" aria-hidden="true"></i></a> */}
            </div>
            <div className="radius-device-body">
              <div className="row">
                <div className="col-lg-4">
                  <div className="switch-box">
                    <strong>Noida</strong>
                    <label className="ice-switch">
                      <input type="checkbox" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="router-port left mt-3">
                    <div className="mxw-1260">
                      <div className="polygon"></div>
                      <div class="port-box">
                        <ul class="port">
                          <li>1
                            <span className="check">
                              <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </span>
                          </li>
                          <li class="assign">2</li>
                          <li>3</li>
                          <li>4</li>
                          <li class="assign">5</li>
                          <li>6</li>
                          <li>7</li>
                          <li>8</li>
                          <li>9</li>
                          <li>10</li>
                          <li>11</li>
                          <li>12</li>
                        </ul>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
            <div className="radius-device-footer schedule-btn">
             <button type="button" class="btn btn-outline" data-bs-toggle="modal" data-bs-target="#radiusPortAuth">Port Authentication Mode</button>
             <button type="button" class="btn btn-outline">Select All</button>
            </div>
          </div>
          <div className="radius-device">
            <div className="radius-device-title">
              <h3>Gurugram - 24 Port</h3>
              {/* <a href="#">Device Configuration <i class="fa fa-external-link" aria-hidden="true"></i></a> */}
            </div>
            <div className="radius-device-body">
              <div className="row">
                <div className="col-lg-4">
                  <div className="switch-box">
                    <strong>Gurugram</strong>
                    <label className="ice-switch">
                      <input type="checkbox" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="router-port left mt-3">
                    <div className="mxw-1260">
                      <div className="polygon"></div>
                      <div class="port-box">
                        <ul class="port24">
                          <li>1</li>
                          <li class="assign">2</li>
                          <li>3</li>
                          <li>4</li>
                          <li class="assign">5</li>
                          <li>6</li>
                          <li>7</li>
                          <li>8</li>
                          <li>9</li>
                          <li>10</li>
                          <li>11</li>
                          <li>12</li>
                          <li>13</li>
                          <li class="assign">14</li>
                          <li>15</li>
                          <li>16</li>
                          <li class="assign">17</li>
                          <li>18</li>
                          <li>19</li>
                          <li>20</li>
                          <li>21</li>
                          <li>22</li>
                          <li>23</li>
                          <li>24</li>
                        </ul>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
            <div className="radius-device-footer schedule-btn">
             <button type="button" class="btn btn-outline" data-bs-toggle="modal" data-bs-target="#radiusPortAuth">Port Authentication Mode</button>
             <button type="button" class="btn btn-outline">Select All</button>
            </div>
          </div>
        </div>
      </div>
      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">
          Cancel
        </button>
        <button type="submit" className="text-btn primary-btn">
          Save
        </button>
      </div>

      <div className="modal fade radius-help-modal" id="radiusHelp" tabindex="-1" aria-labelledby="radiusHelp" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="radiusHelpLabel">RADIUS Configuration</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p><strong>Configure the 802.1x port authentication for the switch Authentication mode would be one of the following:</strong></p>
              <ul>
                <li>
                  <span>
                    <img src={Auto} alt="Unauthorized" />
                  </span>
                  <p>
                    <strong>Auto</strong>
                    A device connected to the port must authenticate to access the network.
                  </p>
                </li>
                <li>
                  <span>
                    <img src={Auth} alt="Unauthorized" />
                  </span>
                  <p>
                    <strong>Authorized</strong>
                    A device connected to the port has access to the network without authentication.
                  </p>
                </li>
                <li>
                  <span>
                    <img src={Unauth} alt="Unauthorized" />
                  </span>
                  <p>
                    <strong>Unauthorized</strong>
                    A device connected to the port does not have access to any network.
                  </p>
                </li>
              </ul>
            </div>            
          </div>
        </div>
      </div>


      <div className="modal fade radius-help-modal" id="radiusPortAuth" tabindex="-1" aria-labelledby="radiusPortAuth" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered medium">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="radiusPortAuthLabel">Device Mode</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p><strong>Select Port Authentication Mode</strong></p>
              <ul>
                <li>
                  <span>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="auth"/>
                    </div>
                  </span>
                  <p>
                    <strong>Auto</strong>
                    A device connected to the port must authenticate to access the network.
                  </p>
                </li>
                <li>
                  <span>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="auth"/>
                    </div>
                  </span>
                  <p>
                    <strong>Authorized</strong>
                    A device connected to the port has access to the network without authentication.
                  </p>
                </li>
                <li>
                  <span>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="auth"/>
                    </div>
                  </span>
                  <p>
                    <strong>Unauthorized</strong>
                    A device connected to the port does not have access to any network.
                  </p>
                </li>
              </ul>
              <div className="modal-footer pb-0">
                <button type="button" class="btn btn-outline" data-bs-dismiss="modal">Cancel</button>
                <button className="text-btn primary-btn" data-bs-dismiss="modal">Save and Continue</button>
              </div>
            </div>            
          </div>
        </div>
      </div>


      {/* <div className="customModal confirm-modal">
        <div className="modal-dialog modal-dialog-centered small">
          <div className="modal-content inc-modal">
            <div className="modal-body">              
              <p>To avoid losing access to the Internet, make sure that all uplink ports are set to Authorized.</p>              
              <div className="modal-footer">
                <button className="text-btn primary-btn">OK</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default radiusConfiguration;