import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import eyeIcon from "../../../assets/images/eye-open.svg";
import eyeCloseIcon from "../../../assets/images/eye-close.svg";
import Select from 'react-select';
const EdgePopup = ({
  showPopUp,
  handleClose,
  headerMessage1,
  handleSuccess,
}) => {
  const [gatewayFields, setGatewayFields] = useState({
    username: "",
    password: "",
    publisher_topic: "",
    subscriber_topic: "",
    gateway_name: "",
    site_name: "",
    organisation_name: "",
    agent_mail: "",
    status: "Active",
  });

  const [missingFields, setMissingFields] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState(null);
  const [requestId, setRequestId] = useState("");
  const [showEmailConfirmationModal, setShowEmailConfirmationModal] =
    useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [gateways, setGateways] = useState([]);
  const [nameError, setNameError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [mails, setMails] = useState([]);
  const [isCustomEmail, setIsCustomEmail] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (showPopUp) {
      setError(null);
    }
    getEdgeDevices();
  }, [showPopUp]);

  useEffect(() => {
    getEdgeDevices();
  }, []);

  useEffect(() => {
    getMails();
  }, []);

  async function getMails() {
    try {
      const api = new allApi();
      let resp = await api.getRequest("api/gateway/unassignedSiteAdmins");
      if (resp && Array.isArray(resp)) {
        setMails(resp);
      }
    } catch (error) {
      setError("Error fetching mails");
    }
  }

  async function getEdgeDevices() {
    try {
      const api = new allApi();
      let response = await api.getRequest("api/gateway/all");
      if (response && Array.isArray(response)) {
        response = response.reverse();
        setGateways(response);
      }
    } catch (error) {
      setError("Error fetching gateways");
    }
  }
  const resetForm = () => {
    setGatewayFields("");
    setMissingFields("");
    setIsCustomEmail(false);
    setPasswordError("");
    setErrorMessage("");
  };
  const handleCancel = () => {
    resetForm();
    handleClose();
  };

  const handleSave = async () => {
    try {
      const requiredFields = [
        "username",
        "password",
        "publisher_topic",
        "subscriber_topic",
        "gateway_name",
        "site_name",
        "organisation_name",
        "agent_mail",
        "status",
      ];
      const missing = requiredFields.filter(
        (field) => !gatewayFields[field].trim()
      );
      setMissingFields(missing);

      if (missing.length > 0) {
        return;
      }

      if (
        gateways.some(
          (gateway) =>
            gateway.gateway_name.toLowerCase().trim() ===
            gatewayFields.gateway_name.toLowerCase().trim()
        )
      ) {
        setNameError(
          "Gateway name already exists. Please use a different name."
        );
        return;
      }
      if (!isValidEmail(gatewayFields.agent_mail)) {
        setError("Please enter a valid email address.");
        return;
      }
     
      // if ( !validateAll(gatewayFields.gateway_name,gatewayFields.site_name,gatewayFields.publisher_topic,gatewayFields.subscriber_topic,gatewayFields.organisation_name,gatewayFields.username)) {
      //   setErrorMessage("No initial blank space and only alphanumeric values are allowed in mandatory fields.");
      //   return;
      // };

      const api = new allApi();
      const payload = { ...gatewayFields };
      const response = await api.postRequest("api/gateway/add", "", payload);

      if (response) {
        setRequestId(response.request_id);
        setGatewayFields({
          username: "",
          password: "",
          publisher_topic: "",
          subscriber_topic: "",
          gateway_name: "",
          site_name: "",
          organisation_name: "",
          agent_mail: "",
        });
        handleClose();
        setShowEmailConfirmationModal(true);
        handleSuccess();
        await getEdgeDevices();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data === "invalid_data") {
          setError("Invalid data provided. Please check your input.");
        } else if (error.response.data === "email_exists") {
          setError(
            "User with this email already exists. Please choose a different email."
          );
        }
        setError(
          "User with mail id is associated with the edge gateway. Please choose another mail id."
        );
      } else {
        setError("Error saving gateway. Please try again.");
        console.error("Error saving gateway:", error);
      }
    }
  };

  const sendEmail = async (requestID) => {
    try {
      const api = new allApi();
      const payload = { request_id: requestID };
      const response = await api.postRequest(
        `api/gateway/config_file`,
        "",
        payload
      );

      if (response && response.status === 201) {
      } else {
        setError("Failed to create config file.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setError("Error sending email. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGatewayFields({ ...gatewayFields, [name]: value });

    if (name === "agent_mail" && !isValidEmail(value)) {
      setMissingFields((prevMissingFields) => [
        ...prevMissingFields,
        "agent_mail",
      ]);
    } else if (missingFields.includes("agent_mail")) {
      setMissingFields((prevMissingFields) =>
        prevMissingFields.filter((field) => field !== "agent_mail")
      );
    }
    if (name === "gateway_name") {
      setNameError("");
    }
  };

  const handleSendEmailConfirmation = async () => {
    setShowEmailConfirmationModal(false);
    await sendEmail(requestId);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  // const validateAll = (gateway_name,site_name,publisher_topic,subscriber_topic,organisation_name,username) => {
  //   const regad = /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/;
  //   return regad.test(gateway_name) && regad.test(site_name) && regad.test(publisher_topic) && regad.test(subscriber_topic) && regad.test(organisation_name) && regad.test(username);
  // };


  const disableAdd = !(
    gatewayFields.gateway_name &&
    gatewayFields.site_name &&
    gatewayFields.publisher_topic &&
    gatewayFields.subscriber_topic &&
    gatewayFields.organisation_name &&
    gatewayFields.username &&
    gatewayFields.password &&
    gatewayFields.agent_mail &&
    !passwordError
  );

  /*const handleMail = (event) => {
    const { value } = event.target;
    setGatewayFields((prevFields) => ({
      ...prevFields,
      agent_mail: value,
    }));
  };*/

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === 'custom') {
      setIsCustomEmail(true);
      setEmailValue('');
      setGatewayFields({ ...gatewayFields, agent_mail: '' });
    } else {
      setIsCustomEmail(false);
      setGatewayFields({ ...gatewayFields, agent_mail: selectedOption ? selectedOption.value : '' });
    }
  };

  const handleCustomEmailChange = (e) => {
    const value = e.target.value;
    setEmailValue(value);
    setGatewayFields({ ...gatewayFields, agent_mail: value });
  };

  const customOptions = [
    { value: 'custom', label: 'Enter E-mail' },
    ...mails.map((mail) => ({ value: mail, label: mail }))
  ];

  const handlePassword = (e) => {
    const value = e.target.value;
    setGatewayFields({ ...gatewayFields, password : value });
  if ( value && !isValidPassword(value)) {
    setPasswordError("Please enter valid password");
  } else {
    setPasswordError("");
  }
};

  return (
    <>
      {showPopUp && (
        <div
          className={"modal fade customModal " + (showPopUp ? " show" : "")}
          id="discover"
          style={{ display: showPopUp ? "block" : "none", zIndex: 1009 }}
        >
          <div className="modal-dialog modal-dialog-centered create-usr-pop">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {headerMessage1}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={handleCancel}
                ></button>
              </div>
              <div className="modal-body">
               
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Gateway Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={"form-control"}
                            id="endIp"
                            name="gateway_name"
                            value={gatewayFields.gateway_name}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Site Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={"form-control"}
                            id="endIp"
                            name="site_name"
                            value={gatewayFields.site_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label
                            htmlFor="snmpTemplate"
                            className="col-form-label"
                          >
                            Publisher Topic
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={"form-control"}
                            id="snmpTemplate"
                            name="publisher_topic"
                            value={gatewayFields.publisher_topic}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Subscriber Topic
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={"form-control"}
                            id="endIp"
                            name="subscriber_topic"
                            value={gatewayFields.subscriber_topic}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Organisation Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={"form-control"}
                            id="endIp"
                            name="organisation_name"
                            value={gatewayFields.organisation_name}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="startIp" className="col-form-label">
                            Username<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={"form-control"}
                            id="username"
                            name="username"
                            value={gatewayFields.username}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Password<span className="text-danger">*</span>
                          </label>
                          <div className="input-wrapper">
                            <input
                              type={showPassword ? "text" : "password"}
                              className={"form-control"}
                              id="password"
                              name="password"
                              value={gatewayFields.password}
                              onChange={handlePassword}
                            />
                            <img
                              src={showPassword ? eyeIcon : eyeCloseIcon}
                              alt={showPassword ? "eye" : "closeeye"}
                              className="toggle-password"
                              onClick={togglePasswordVisibility}
                            />

                            <img
                              src={showPassword ? eyeIcon : eyeCloseIcon}
                              alt={showPassword ? "eye" : "closeeye"}
                              className="toggle-password"
                              onClick={togglePasswordVisibility}
                            />
                            {passwordError && (
                              <p className="text-danger">{passwordError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endIp" className="col-form-label">
                            Mail ID<span className="text-danger">*</span>
                          </label>

                          {!isCustomEmail ? (
                            <Select                              
                              id="endIP"
                              name="agent_mail"
                              className="custom-mail"
                              value={customOptions.find(option => option.value === gatewayFields.agent_mail)}
                              onChange={handleSelectChange}
                              options={customOptions}
                              placeholder="Select Mail"
                              isSearchable
                            />
                          ) : (
                            <input
                              type="text"
                              className={`form-control ${missingFields.includes("agent_mail") ? "missing-field" : ""
                                }`}
                              id="customEmail"
                              name="agent_mail"
                              value={emailValue}
                              onChange={handleCustomEmailChange}
                              placeholder="Enter your email"
                              onBlur={() => {
                                if (!emailValue) {
                                  setIsCustomEmail(false);
                                }
                              }}
                            />
                          )}
                         
                        </div>
                      </div>
                      {nameError && (
                        <div className="alert alert-danger">{nameError}</div>
                      )}
                       {error && <div className="alert alert-danger">{error}</div>}
                       {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>)}
                    </form>
                    <div className="modal-footer pb-0">
                      <button
                        type="button"
                        className="btn btn-outline"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`text-btn primary-btn`}
                        disabled={disableAdd}
                        onClick={() => handleSave()}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showEmailConfirmationModal && (
            <Modal
              show={showEmailModal}
              onHide={() => setShowEmailModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="inc-alert"
            >
              <div className="modal-dialog email-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <h3>Email Confirmation</h3>
                    <p>
                      Are you sure want to send an email? <br /> In the email
                      you will receive the below content: <br />
                      <div className="text-start mt-3">
                        1. EDGE application download link. <br />
                        2. Configuration file for setting up the service on
                        HOST.
                        <br />
                        3. EDGE application installation and operating steps.
                      </div>
                    </p>
                    <div className="confirm-btn">
                      <button
                        type="button"
                        className="btn btn-outline"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-fill"
                        onClick={() => handleSendEmailConfirmation()}
                        data-bs-dismiss="modal"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default EdgePopup;
