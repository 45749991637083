import React from "react";
// import errorIcon from "../../../assets/images/error-2.png";

function fastRoaming() {
  return (
    <div className="tab-form mt-3">
      <p>Enables roaming at the network level, including OKC roaming. When enabled, you can also enable
        Fast Roaming (802.11r) for <br />an SSID in Wireless Settings <i className="fa fa-angle-right" aria-hidden="true"></i> Radio Settings.</p>

      <div className="row mt-4">
        <div className="col-lg-4">
          <div className="switch-box">
            <strong>Enable</strong>
            <label className="ice-switch">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </div>

      {/* <div className="customModal confirm-modal">
        <div className="modal-dialog modal-dialog-centered small">
          <div className="modal-content inc-modal">
            <div className="modal-body">
              <img src={errorIcon} alt="error" />
              <p>Disabling Fast Roaming will also disable Fast Roaming (802.11r) for all SSIDs in this network location.</p>
              <h3>Do you want to continue?</h3>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline">No</button>
                <button className="text-btn primary-btn">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default fastRoaming;
