import React from "react";

function loadBalancing() {
  return (
    <div>
      <p>
        Load balancing facilitates equal distribution of clients across your available access points.
        When an AP reaches its capacity based on number of clients or channel utilization, load balancing prevents new clients from connecting.
        It can prevent far away clients with low RSSI from connecting, and dissociate connected clients so they can connect to a neighboring AP.
      </p>

      <div className="tab-form">
        <div className="row mb-4">
          <div className="col-lg-6">
            <div className="switch-box">
              <strong>Load Balancing</strong>
              <label className="ice-switch">
                <input type="checkbox" />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="mb-4">

          <div className="load-list">
            <div className="load-type">
              <label className="checkbox">
                <input type="checkbox" />
                <span className="indicator"></span>
                <p>Maximum number of clients per radio</p>
              </label>
            </div>
            <div>
              Slider
            </div>
          </div>

          <div className="load-list">
            <div className="load-type">
              <label className="checkbox">
                <input type="checkbox" />
                <span className="indicator"></span>
                <p>Balance on client Rx RSSI</p>
              </label>
            </div>
            <div>
              Slider
            </div>
          </div>

          <div className="load-list">
            <div className="load-type">
              <label className="checkbox">
                <input type="checkbox" />
                <span className="indicator"></span>
                <p>Balance on channel utilization</p>
              </label>
            </div>
            <div>
              Slider
            </div>
          </div>

        </div>

        <div className="row mb-4 mt-4">
          <div className="col-lg-6">
            <div className="switch-box">
              <strong>Disassociate sticky clients</strong>
              <label className="ice-switch">
                <input type="checkbox" />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>
        <p>Disassociate clients by force when RSSI gets too low so they can connect to a stronger AP.</p>
        <div>
          Slider
        </div>



      </div>

      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">Cancel</button>
        <button type="submit" className="text-btn primary-btn">Save</button>
      </div>
    </div>
  );
}

export default loadBalancing;
