import React, { useState, useEffect } from "react";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import eyeIcon from "../../../../assets/images/eye-close.svg";
import eyeCloseIcon from "../../../../assets/images/eye-close.svg";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function AddSSIDModal({ getList, onClose }) {
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showData, setShowData] = useState(false);
  const [showPopUp, setShowPopUp] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [formData, setFormData] = useState({
    ssid: "",
    broadcastStatus: "1",
    band: [],
    vlanId: "",
    bandSteeringSt: "0",
    fastRoamingSt: "0",
    clientIsolation: "0",
    allowLocalUIAccess: "0",
    scheduleEnabled: "0",
    kvrStatus: "0",
    securityAuthentication: "",
    multiPsK: false,
    password: "",
    selectedSchedule: "",
    schedule: "",
  });
  const [schedule, setSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [schedules, setSchedules] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [vlanList, setVlanList] = useState([]);
  const [isCreatingNewSchedule, setIsCreatingNewSchedule] = useState(false);
  const [newScheduleName, setNewScheduleName] = useState("");
  const [scheduleName, setScheduleName] = useState("");
  const [schedulesList, setSchedulesList] = useState([]);
  const [showScheduleForm, setShowScheduleForm] = useState(true);
  const [slotsBooked, setSlotsBooked] = useState(false);
  const [selectedSSID, setSelectedSSID] = useState(null);

  useEffect(() => {
    fetchSchedules();
    fetchVlanList();
    if (selectedSSID) {
      createScheduleSetting(selectedSSID);
    }
  }, [selectedSSID]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "band") {
        if (value === "All") {
          setFormData({
            ...formData,
            band: checked ? ["2.4 GHz", "5 GHz"] : [],
          });
        } else {
          setFormData((prevData) => {
            const newBand = checked
              ? [...prevData.band, value].filter((v) => v !== "All")
              : prevData.band.filter((band) => band !== value);
            return {
              ...prevData,
              band: newBand,
            };
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: checked ? "1" : "0",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const fetchVlanList = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/vlan/list");
      if (response) {
        setVlanList(response);
      }
    } catch (error) {
      console.error("Error fetching VLAN list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e, actionType) => {
    e.preventDefault();
    try {
      const payload = {
        ssid: formData.ssid,
        broadcastStatus: formData.broadcastStatus,
        band: formData.band.join(", "),
        vlanId: formData.vlanId,
        bandSteeringSt: formData.bandSteeringSt,
        fastRoamingSt: formData.fastRoamingSt,
        clientIsolation: formData.clientIsolation,
        allowLocalUIAccess: formData.allowLocalUIAccess,
        scheduleEnabled: formData.scheduleEnabled,
        kvrStatus: formData.kvrStatus,
        securityAuthentication: formData.securityAuthentication,
        password: formData.password,
        schedule:
          formData.scheduleEnabled === "1"
            ? {
                scheduleName: formData.selectedSchedule || newScheduleName,
                allDay: formData.allDay,
                scheduleSetting: createScheduleSetting(schedule),
              }
            : undefined,
      };

      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const response = await api.postRequest(
        "api/wireless/configuration/addSsid?networkId=6001",
        "",
        payload
      );

      if (response.message) {
        setShowPopUp(false);
        setShowData(true);
        if (actionType === "saveAndConfigure") {
          navigate(`/wifi-settings`);
        }
      }
    } catch (error) {
      console.error("Error adding SSID:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const createScheduleSetting = (schedule) => {
    const defaultStart = "2359";
    const defaultEnd = "0000";

    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const scheduleData = {
      schDays: "1111111",
    };

    daysOfWeek.forEach((day, index) => {
      scheduleData[`schOnTime${index}`] =
        schedule[day]?.[0]?.startTime || defaultStart;
      scheduleData[`schOffTime${index}`] =
        schedule[day]?.[0]?.endTime || defaultEnd;
    });

    return scheduleData;
  };

  const handleSuccessClose = async () => {
    setShowData(false);
    window.location.reload();
  };

  const handlePopUpClose = () => {
    setShowPopUp(false);
    window.location.reload();
    navigate("/wifi-config");
  };

  const MAX_TIME_SLOTS = 3;
  const addTimeSlot = (day) => {
    const updatedSchedule = { ...schedule };

    // Check if the day has fewer than 3 time slots before adding a new one
    if (updatedSchedule[day].length < MAX_TIME_SLOTS) {
      updatedSchedule[day].push({ startTime: null, endTime: null });
      setSchedule(updatedSchedule);
    } else {
      console.log(`Maximum of ${MAX_TIME_SLOTS} time slots reached for ${day}`);
    }
  };
  // const addTimeSlot = (day) => {
  //   const updatedSchedule = { ...schedule };
  //   updatedSchedule[day].push({ startTime: "", endTime: "" });
  //   setSchedule(updatedSchedule);
  // };

  const removeTimeSlot = (day, index) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].splice(index, 1);
    setSchedule(updatedSchedule);
  };
  const handleScheduleChange = (day, index, field, value) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day][index][field] = value;
    setSchedule(updatedSchedule);
  };

  const handlePopUp = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "band") {
        if (value === "All") {
          setFormData({
            ...formData,
            band: checked ? ["2.4 GHz", "5 GHz"] : [],
          });
        } else {
          setFormData((prevData) => {
            const newBand = checked
              ? [...prevData.band, value].filter((v) => v !== "All")
              : prevData.band.filter((band) => band !== value);
            return {
              ...prevData,
              band: newBand,
            };
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: checked ? "1" : "0",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setPopUp((prevState) => !prevState);
  };

  const fetchSchedules = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/configuration/getAllSsidSchedule?networkId=6001"
      );
      if (response.status === "OK") {
        setSchedules(response.response || []);
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleScheduleToggle = () => {
    setIsCreatingNewSchedule(!isCreatingNewSchedule);
    setNewScheduleName("");
    setSchedule({
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    });
  };

  const closeSchedule = () => {
    setShowScheduleForm(false);
    setSlotsBooked(false);
    if (onClose) onClose();
  };

  return (
    <>
      {showPopUp && (
        <div>
          <div className="customModal">
            <div className="c-modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New SSID</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handlePopUpClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="add-ssid-body">
                      <h4>Wireless Settings</h4>
                      <div className="row d-flex">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            name="ssid"
                            placeholder="SSID"
                            value={formData.ssid}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <div className="switch-box">
                            <strong>Broadcast SSID</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="broadcastStatus"
                                checked={formData.broadcastStatus === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <h4 className="mt-3">Band</h4>
                      <p>
                        Some legacy clients may not support WPA3 security.
                        Please select WPA3 Personal <br />
                        Mixed (WPA2+WPA3) mode.
                      </p>
                      <div className="row">
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="band"
                              value="All"
                              checked={formData.band.length === 2}
                              onChange={handleChange}
                            />
                            <span className="indicator"></span>
                            <strong>All</strong>
                          </label>
                        </div>
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="band"
                              value="2.4 GHz"
                              checked={formData.band.includes("2.4 GHz")}
                              onChange={handleChange}
                            />
                            <span className="indicator"></span>
                            <strong>2.4 GHz</strong>
                          </label>
                        </div>
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="band"
                              value="5 GHz"
                              checked={formData.band.includes("5 GHz")}
                              onChange={handleChange}
                            />
                            <span className="indicator"></span>
                            <strong>5 GHz</strong>
                          </label>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <h4 className="mt-3">Security Settings</h4>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="security" className="col-form-label">
                            Security<span className="text-danger">*</span>
                          </label>
                          <select
                            id="securityAuthentication"
                            className="form-select"
                            name="securityAuthentication"
                            placeholder="Select Security"
                            value={formData.securityAuthentication}
                            onChange={handleChange}
                          >
                            <option value="">Select Security</option>
                            <option value="Open">Open</option>
                            <option value="WPA2 Personal">WPA2 Personal</option>
                            <option value="WPA2 Personal Mixed">
                              WPA2 Personal Mixed
                            </option>
                            <option value="WPA3 Personal">WPA3 Personal</option>
                            <option value="WPA3 Personal Mixed">
                              WPA3 Personal Mixed (WPA2 + WPA3)
                            </option>
                          </select>
                        </div>
                      </div>

                      {(formData.securityAuthentication === "WPA2 Personal" ||
                        formData.securityAuthentication ===
                          "WPA2 Personal Mixed") && (
                        <>
                          {/* Show MPSK for WPA2 options */}
                          <div className="row mt-3">
                            <div className="col-lg-12">
                              <div className="switch-box">
                                <strong>Multi Pre-shared Key (MPsK)</strong>
                                <label className="ice-switch">
                                  <input
                                    type="checkbox"
                                    name="multiPsK"
                                    checked={formData.multiPsK}
                                    onChange={() =>
                                      setFormData((prev) => ({
                                        ...prev,
                                        multiPsK: !prev.multiPsK,
                                      }))
                                    }
                                  />
                                  <span className="slider"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Show Password for WPA2 options */}
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <label
                                htmlFor="password"
                                className="col-form-label"
                              >
                                Password<span className="text-danger">*</span>
                              </label>
                              <div className="input-wrapper">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  className="form-control"
                                  name="password"
                                  placeholder="Password"
                                  value={formData.password}
                                  onChange={handleChange}
                                />
                                <img
                                  src={showPassword ? eyeIcon : eyeCloseIcon}
                                  alt={
                                    showPassword
                                      ? "Hide password"
                                      : "Show password"
                                  }
                                  className="show-password"
                                  onClick={togglePasswordVisibility}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {/* Show only Password for WPA3 options */}
                      {(formData.securityAuthentication === "WPA3 Personal" ||
                        formData.securityAuthentication ===
                          "WPA3 Personal Mixed") && (
                        <div className="row mt-3">
                          <div className="col-lg-6">
                            <label
                              htmlFor="password"
                              className="col-form-label"
                            >
                              Password<span className="text-danger">*</span>
                            </label>
                            <div className="input-wrapper">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                              />
                              <img
                                src={showPassword ? eyeIcon : eyeCloseIcon}
                                alt={
                                  showPassword
                                    ? "Hide password"
                                    : "Show password"
                                }
                                className="show-password"
                                onClick={togglePasswordVisibility}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="border-line"></div>
                      <h4 className="mt-3">Network Settings</h4>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="vlanId" className="col-form-label">
                            VLAN ID<span className="text-danger">*</span>
                          </label>
                          <select
                            id="vlanId"
                            className="form-select"
                            name="vlanId"
                            value={formData.vlanId}
                            onChange={handleChange}
                          >
                            <option value="">Select VLAN</option>
                            {vlanList.map((vlan) => (
                              <option key={vlan.vlan_id} value={vlan.vlan_id}>
                                {vlan.vlan_name} - {vlan.vlan_id}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="border-line"></div>
                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong>Client Isolation</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="clientIsolation"
                                checked={formData.clientIsolation === "1"}
                                onChange={handleChange}
                              />{" "}
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="border-line"></div>
                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong>Access To Local UI</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="allowLocalUIAccess"
                                checked={formData.allowLocalUIAccess === "1"}
                                onChange={handleChange}
                                disabled={formData.clientIsolation !== "1"} // Disable if Client Isolation is not enabled
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="border-line"></div>
                      <h4 className="mt-3 mb-4">Radio Settings</h4>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>Band Steering</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="bandSteeringSt"
                                checked={formData.bandSteeringSt === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>Fast Roaming (802.11r)</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="fastRoamingSt"
                                checked={formData.fastRoamingSt === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>802.11k/v</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="kvrStatus"
                                checked={formData.kvrStatus === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="border-line"></div>
                        <div className="row mt-3">
                          <div className="col-lg-12">
                            <div className="switch-box">
                              <strong>Enable SSID Schedule</strong>
                              <label className="ice-switch">
                                <input
                                  type="checkbox"
                                  name="scheduleEnabled"
                                  checked={formData.scheduleEnabled === "1"}
                                  onChange={handlePopUp}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        {formData.scheduleEnabled === "1" && (
                          <div className="mt-3">
                            <div className="row">
                              <div className="col-lg-12">
                                <label
                                  htmlFor="vlanId"
                                  className="col-form-label"
                                >
                                  Select a Schedule
                                </label>
                                <select
                                  className="form-select"
                                  value={formData.schedule}
                                  onChange={(e) => {
                                    const selectedSchedule = schedules.find(
                                      (schedule) =>
                                        schedule.scheduleName === e.target.value
                                    );
                                    setFormData({
                                      ...formData,
                                      schedule: e.target.value,
                                      scheduleName: selectedSchedule
                                        ? selectedSchedule.scheduleName
                                        : "",
                                      scheduleSetting: selectedSchedule
                                        ? selectedSchedule.scheduleSetting
                                        : {},
                                    });
                                  }}
                                >
                                  <option value="">Select a Schedule</option>
                                  {schedules.map((schedule) => (
                                    <option
                                      key={schedule.scheduleId}
                                      value={schedule.scheduleName}
                                    >
                                      {schedule.scheduleName}
                                    </option>
                                  ))}
                                </select>

                                <br />
                                <p className="or-seperator"><span>OR</span></p>
                                <div className="row mt-3">
                                  <div className="col-lg-12">
                                    <div className="switch-box">
                                      <strong className="pointer"
                                        onClick={() =>
                                          setIsCreatingNewSchedule(
                                            !isCreatingNewSchedule
                                          )
                                        }
                                      >
                                        <i class="fa fa-plus" aria-hidden="true"></i> Configure New Schedule
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                {showPopUp && (
                                  <div>
                                    {showScheduleForm && !slotsBooked ? (
                                      <div className="schedule-week">
                                        <h4>Schedule Name</h4>
                                        <div className="row d-flex">
                                          <div className="col-lg-6">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Schedule Name"
                                              value={scheduleName}
                                              onChange={(e) => {
                                                setScheduleName(e.target.value);
                                                setFormData((prev) => ({
                                                  ...prev,
                                                  selectedSchedule:
                                                    e.target.value, // Update form data with schedule name
                                                }));
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="border-line"></div>
                                        <p>
                                          Location Timezone - America/New_York
                                          (ET)
                                        </p>
                                        <h4>Days</h4>
                                        <p>
                                          You can add a maximum of 3 time slots
                                          per day.
                                        </p>
                                        <div className="schedule-slot-scroller">
                                          {Object.keys(schedule).map((day) => (
                                            <div
                                              className="schedule-days"
                                              key={day}
                                            >
                                              <div className="sc-day-title">
                                                <h4>{day}</h4>
                                                <div>
                                                  <button
                                                    type="button" // Add this line to prevent form submission
                                                    className="icon-btn"
                                                    onClick={() =>
                                                      addTimeSlot(day)
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-plus"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="schedule-time">
                                                {schedule[day].map(
                                                  (slot, index) => (
                                                    <div
                                                      className="schedule-time-box"
                                                      key={index}
                                                    >
                                                      <div className="start-time">
                                                        <h4>Start Time</h4>
                                                        <TimePicker
                                                          value={
                                                            slot.startTime
                                                              ? dayjs(
                                                                  slot.startTime,
                                                                  "HHmm"
                                                                )
                                                              : null
                                                          }
                                                          onChange={(
                                                            newValue
                                                          ) => {
                                                            const formattedTime =
                                                              newValue
                                                                ? newValue.format(
                                                                    "HHmm"
                                                                  )
                                                                : "";
                                                            handleScheduleChange(
                                                              day,
                                                              index,
                                                              "startTime",
                                                              formattedTime
                                                            );
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              className="time-picker-input"
                                                            />
                                                          )}
                                                          components={{
                                                            OpenPickerIcon:
                                                              () => (
                                                                <AccessTimeIcon fontSize="small" />
                                                              ),
                                                          }}
                                                        />
                                                      </div>
                                                      <div className="end-time">
                                                        <h4>End Time</h4>
                                                        <TimePicker
                                                          value={
                                                            slot.endTime
                                                              ? dayjs(
                                                                  slot.endTime,
                                                                  "HHmm"
                                                                )
                                                              : null
                                                          }
                                                          onChange={(
                                                            newValue
                                                          ) => {
                                                            const formattedTime =
                                                              newValue
                                                                ? newValue.format(
                                                                    "HHmm"
                                                                  )
                                                                : "";
                                                            handleScheduleChange(
                                                              day,
                                                              index,
                                                              "endTime",
                                                              formattedTime
                                                            );
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              className="time-picker-input"
                                                            />
                                                          )}
                                                          components={{
                                                            OpenPickerIcon:
                                                              () => (
                                                                <AccessTimeIcon fontSize="small" />
                                                              ),
                                                          }}
                                                        />
                                                      </div>
                                                      <button
                                                        type="button"
                                                        className="icon-btn"
                                                        onClick={() =>
                                                          addTimeSlot(day)
                                                        }
                                                      >
                                                        <i
                                                          className="fa fa-plus"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </button>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="schedule-wrapper">
                                        {schedulesList.length > 0 ? (
                                          schedulesList.map((scheduleItem) => (
                                            <div
                                              className="schedule-item"
                                              key={scheduleItem.scheduleId}
                                            >
                                              <div className="schedule-detail">
                                                <h5>
                                                  {scheduleItem.scheduleName}
                                                </h5>
                                                <p>
                                                  Status:{" "}
                                                  {scheduleItem.scheduleStatus ===
                                                  1
                                                    ? "Active"
                                                    : "Inactive"}
                                                </p>
                                                <p>
                                                  Created At:{" "}
                                                  {new Date(
                                                    scheduleItem.createdAt *
                                                      1000
                                                  ).toLocaleString()}
                                                </p>
                                              </div>
                                            </div>
                                          ))
                                        ) : (
                                          <p>No schedules found.</p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </LocalizationProvider>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-outline"
                        onClick={handlePopUpClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="text-btn primary-btn"
                        onClick={(e) => handleSubmit(e, "save")}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="text-btn primary-btn"
                        onClick={(e) => handleSubmit(e, "saveAndConfigure")}
                      >
                        Save & Configure
                      </button>
                    </div>
                  </form>
                </div>
              </div> 

            </div>
          </div>
        </div>
      )}
      <Modal
        show={showData}
        onHide={handleSuccessClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>SSID created Successfully!</p>
        <Button onClick={() => handleSuccessClose()}>OK</Button>
      </Modal>
    </>
  );
}

export default AddSSIDModal;
