import React from "react";

function Reports() {
  return (
    <div>
      <h4>Pick Widgets</h4>
      <p>Add one or more widget to the report</p>

      <div className="accordion mt-4" id="accordionWidgets">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              INC Device
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </th>
                      <th>Location Name</th>
                      <th>Devices</th>
                      <th>Selected Device</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>Report One</td>
                      <td>12</td>
                      <td>
                        All Devices | <span className="underline">Change</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>Report Two</td>
                      <td>12</td>
                      <td>
                        All Devices | <span className="underline">Change</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Gurugram Testing
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </th>
                      <th>Location Name</th>
                      <th>Devices</th>
                      <th>Selected Device</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>Report One</td>
                      <td>12</td>
                      <td>
                        All Devices | <span className="underline">Change</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>Report Two</td>
                      <td>12</td>
                      <td>
                        All Devices | <span className="underline">Change</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Noida Device
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="inc-card-table">
                <table className="full-table m-head">
                  <thead>
                    <tr>
                      <th>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </th>
                      <th>Location Name</th>
                      <th>Devices</th>
                      <th>Selected Device</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>Report One</td>
                      <td>12</td>
                      <td>
                        All Devices | <span className="underline">Change</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>Report Two</td>
                      <td>12</td>
                      <td>
                        All Devices | <span className="underline">Change</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
