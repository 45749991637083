import React, { useEffect, useState } from "react";
import OTAs from "../../../assets/images/ota-sc.png";
import OTAupi from "../../../assets/images/ota-upi.png";
import OTAupd from "../../../assets/images/ota-upd.png";
import OTAf from "../../../assets/images/ota-fl.png";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import errorIcon from "../../../assets/images/error-2.png";
import successIcon from "../../../assets/images/success_lg.png";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function OTAManagement() {
  const [isEnabled, setIsEnabled] = useState(false);
  const [time, setTime] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [listing, setListing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firmwareFields, setFirmwareFields] = useState({
    protocolType: "",
    deviceType: "",
    modelName: "",
    version: "",
    firmware: "",
    modelFamily: "",
  });
  const [models, setModels] = useState([]);
  const [httpDevices, setHttpDevices] = useState([]);
  const [snmpDevices, setSnmpDevices] = useState([]);
  const [firmwareVersions, setFirmwareVersions] = useState([]);
  const [httpModelDetails, setHttpModelDetails] = useState(null);
  const [firmwareId, setFirmwareId] = useState("");
  const [snmpFamilies, setSnmpFamilies] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [scheduledDevices, setScheduledDevices] = useState(0);
  const [pendingDevices, setPendingDevices] = useState(0);
  const [updatedDevices, setUpdatedDevices] = useState(0);
  const [snmpDeviceType, setSnmpDeviceType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [firmwaresPerPage] = useState(10);
  const [listingsPerPage] = useState(10);
  const [currentListPage, setCurrentListPage] = useState(1);
  const [scheduleModel,setScheduleModel] = useState(false);
  const [showFailurePopup,setShowFailurePopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccessAddModal,setShowSuccessAddModal] = useState(false);
  const [showFirmwareFailurePopup,setShowFirmwareFailurePopup] = useState(false);

  useEffect(() => {
    firmwareDetails();
    overviewDetails();
    fetchModels();
    getSnmpFamily();
    getSnmpDeviceType();
  }, []);

  const toggleSwitch = () => {
    setIsEnabled(!isEnabled);
  };

  const handleAddFirmwareClick = () => {
    setIsModalOpen(true);
  };
  const handleSave = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  async function firmwareDetails() {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest("api/firmware/list");
      if (response) {
        setDetails(response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching firmware data:", error);
    } finally {
      setLoading(false);
    }
  }

  async function overviewDetails() {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/firmware/firmwareUpdateStatus"
      );
      if (response) {
        // Filter by protocol type
        const filteredHttpDevices = response.filter(
          (item) => item.protocol === "http"
        );
        const filteredSnmpDevices = response.filter(
          (item) => item.protocol === "SNMP"
        );

        // Filter for scheduled and pending statuses
        const scheduledDevices = response.filter(
          (item) => item.nextStep === "Update Now"
        ).length;

        const pendingDevices = response.filter(
          (item) => item.updateStatus === "Pending"
        ).length;

        const failedDevices = response.filter(
          (item) => item.updateStatus === "Failed Devices"
        ).length;

        const updatedDevices = response.filter(
          (item) => item.updateStatus === "Up-to-date"
        ).length;
        // Set the filtered data in state updatedDevices
        setHttpDevices(filteredHttpDevices);
        setSnmpDevices(filteredSnmpDevices);

        // Set the complete listing if needed
        setListing(response);
        setScheduledDevices(scheduledDevices);
        setPendingDevices(pendingDevices);
        setUpdatedDevices(updatedDevices);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching overview data:", error);
    } finally {
      setLoading(false);
    }
  }

  const fetchModels = async (deviceType) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/model/model-list?deviceType=${deviceType}`
      );
      if (response) {
        const modelsList = response.map((model) => ({
          value: model,
          label: model,
        }));
        setModels(modelsList);
      }
    } catch (error) {
      console.error("Error fetching model list:", error);
    }
  };

  const fetchModelDetails = async (deviceType, modelName) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/model/getHttpModel?deviceType=${deviceType}&modelName=${modelName}`
      );
      setHttpModelDetails(response);
    } catch (error) {
      console.error("Error fetching model details:", error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // Check file size (e.g., limit to 100MB)
      if (file.size > 100 * 1024 * 1024) {
        alert("File size exceeds the limit of 100MB!");
        return;
      }
      setFirmwareFields({
        ...firmwareFields,
        firmware: file,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFirmwareFields({ ...firmwareFields, [name]: value });
  };

  const handleModelChange = (e) => {
    const { name, value } = e.target;

    setFirmwareFields({
      ...firmwareFields,
      [name]: value,
    });

    // Check if the protocol is set and use it accordingly
    const selectedProtocol = firmwareFields.protocolType; // Get the selected protocol

    if (name === "deviceType") {
      fetchModels(value, selectedProtocol); // Pass protocolType to fetchModels
    } else if (name === "modelName" && firmwareFields.deviceType) {
      fetchModelDetails(firmwareFields.deviceType, value, selectedProtocol);
    }
  };

  const handleProtocolChange = (e) => {
    const { name, value } = e.target;
    setFirmwareFields({ ...firmwareFields, [name]: value });
  };

  const addFirmware = async () => {
    if (!firmwareFields.modelName || !firmwareFields.firmware) {
      alert("Please fill in all required fields and select a file!");
      return;
    }

    try {
      const api = new allApi();
      const formData = new FormData();
      formData.append("firmware", firmwareFields.firmware);
      formData.append("modelName", firmwareFields.modelName);

      if (firmwareFields.protocolType === "HTTP") {
        if (!firmwareFields.version || !firmwareFields.deviceType) {
          alert("Please fill in all fields for HTTP protocol!");
          return;
        }
        formData.append("version", firmwareFields.version);
        formData.append("deviceType", firmwareFields.deviceType);
        console.log("formdata", formData);
        const response = await api.postMultiRequest(
          "api/firmware/addHttpFirmware",
          formData
        );


        if (response && response.success) {
          setSuccessMessage("HTTP Firmware uploaded successfully!");
          setIsModalOpen(false);
          setTimeout(() => setSuccessMessage(""), 3000);
          setShowSuccessAddModal(true);
        }
      } else if (firmwareFields.protocolType === "SNMP") {
        formData.append("version", firmwareFields.version);
        formData.append("deviceType", firmwareFields.deviceType);
        console.log("formData", formData);
        const response = await api.postMultiRequest(
          "api/firmware/addSnmpFirmware",
          formData
        );

        if (response && response.success) {
          setIsModalOpen(false);
          setShowSuccessAddModal(true);
          setSuccessMessage("SNMP Firmware uploaded successfully!");
          setTimeout(() => setSuccessMessage(""), 3000);
          
        }
      }
      setFirmwareFields({
        protocolType: "",
        modelName: "",
        version: "",
        firmware: null,
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding firmware details", error);
      setShowFirmwareFailurePopup(true);
      
    }
  };

  const fetchFirmwareVersions = async (modelName) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/firmware/snmpVersions?modelName=${modelName}`
      );
      if (response) {
        console.log(`Fetched firmware versions for ${modelName}:`, response);
        // setFirmwareVersions((prev) => ({
        //   ...prev,
        //   [modelName]: response,
        // }));
        setFirmwareVersions(response);
      }
    } catch (error) {
      console.error("Error fetching firmware versions:", error);
    }
  };

  const handleVersionChange = (deviceId, selectedFirmwareId) => {
    setListing((prevListing) =>
      prevListing.map((device) =>
        device.deviceId === deviceId
          ? {
            ...device,
            firmware_id: selectedFirmwareId,
          }
          : device
      )
    );
  };

  const handleUpdateClick = async (device) => {
    const api = new allApi();
    console.log(device, "device");
    try {
      const scheduleTime = new Date().toISOString();
  
      if (device.protocol === "http") {
        const payload = {
          deviceId: device.deviceId,
        };
  
        const response = await api.postRequest(
          "api/firmware/httpFirmwareUpgrade",
          "",
          payload
        );
  
        if (response) {
          console.log("HTTP Device Firmware Upgraded Successfully!");
          // Open the modal upon successful response
          setShowSuccessModal(true);
        } else {
          console.log("Failed to upgrade HTTP firmware. Please try again.");
          console.log("showFailurePopup:", showFailurePopup);
          setShowFailurePopup(true);
        }
      } else if (device.protocol === "SNMP") {
        console.log(firmwareVersions, "kkk");
        let selectedFirmware = firmwareVersions.find(
          (item) => item.deviceId === device.deviceId
        )?.firmwareId; // Using find to get the firmwareId
  
        const payload = {
          device_id: device.deviceId,
          firmware_id: selectedFirmware,
          schedule_time: scheduleTime,
        };
  
        const response = await api.postRequest(
          "api/firmware/snmpFirmwareUpgrade",
          "",
          payload
        );
  
        if (response) {
          console.log("SNMP Device Firmware Upgraded Successfully!");
          // Open the modal upon successful response
          setShowSuccessModal(true);
        } else {
          console.log(
            `SNMP Firmware Upgrade Failed: ${response.message || "Unknown error"}`);
            console.log("showFailurePopup:", showFailurePopup);
            setShowFailurePopup(true);
        }
      } else {
        console.log("Unsupported protocol type for firmware upgrade.");
      }
    } catch (error) {
      console.error("Error upgrading firmware:", error);
      setShowFailurePopup(true);
    }
  };
  

  const handleDownloadClick = async (remoteFilePath) => {
    try {
      const api = new allApi();
      const downloadUrl = `api/firmware/downloadFile?remoteFilePath=features.csv&downloadPath=/home/siddhartha/Downloads/file1001 `;
      const response = await api.getRequest(downloadUrl);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", remoteFilePath.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading firmware file:", error);
    }
  };

  const getSnmpFamily = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/model/snmpFamilyModels");
      if (response) {
        setSnmpFamilies(response);
      }
    } catch (error) {
      console.error("Failed to get SNMP family", error);
    }
  };

  const getSnmpDeviceType = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/firmware/snmpDeviceType");
      console.log("API response:", response); // Log response to see the data
      if (Array.isArray(response)) {
        setSnmpDeviceType(response); // Set response array to state
      }
    } catch (error) {
      console.log("Error fetching device type", error);
    }
  };

  // Handle dropdown value change
  const handleDeviceType = (e) => {
    const { name, value } = e.target;
    setFirmwareFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handlesnmpFamily = (e) => {
    const familyName = e.target.value;
    setFirmwareFields({
      ...firmwareFields,
      modelFamily: familyName,
    });
    if (familyName) {
      fetchSnmpModelsForFamily(familyName);
    }
  };

  const fetchSnmpModelsForFamily = async (familyName) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/model/modelsForFamily?family_name=${familyName}`
      );
      if (response) {
        setModels(response);
      }
    } catch (error) {
      console.error("Error fetching SNMP models for family:", error);
    }
  };

  const indexOfLastListing = currentListPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const totalFilteredListings = listing.length;
  const currentListings = listing.slice(
    indexOfFirstListing,
    indexOfLastListing
  );

  const indexOfLastFirmware = currentPage * firmwaresPerPage;
  const indexOfFirstFirmwares = indexOfLastFirmware - firmwaresPerPage;
  const totalFilteredFirmwares = details.length;
  const currentDetails = details.slice(
    indexOfFirstFirmwares,
    indexOfLastFirmware
  );


  const totalPages = Math.ceil(totalFilteredFirmwares / firmwaresPerPage);
  const totalListPage = Math.ceil(totalFilteredListings / listingsPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleListNextPage = () => {
    if (currentListPage < totalListPage) {
      setCurrentListPage(currentListPage + 1);
    }
  }
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleListPrevPage = () => {
    if (currentListPage > 1) {
      setCurrentListPage(currentListPage - 1);
    }
  }
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handleListLastPage = () => {
    setCurrentListPage(totalListPage);
  }

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const handleListPageClick = (page) => {
    setCurrentListPage(page);
  };

  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };
  const getPageNum = () => {
    const range = 1;
    const start = Math.max(1, currentListPage - range);
    const end = Math.min(totalListPage, currentListPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="overview-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#overview"
                  type="button"
                  role="tab"
                  aria-controls="overview"
                  aria-selected="true"
                >
                  Overview
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="firmware-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#firmware"
                  type="button"
                  role="tab"
                  aria-controls="firmware"
                  aria-selected="false"
                >
                  Firmware
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="scheduled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#scheduled"
                  type="button"
                  role="tab"
                  aria-controls="scheduled"
                  aria-selected="false"
                >
                  Scheduled
                </button>
              </li>
            </ul>
            <button
              className="icon-btn"
              title="Refresh"
              onClick={() => overviewDetails()}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </button>
          </div>
          <div className="inc-card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="overview"
                role="tabpanel"
                aria-labelledby="overview-tab"
                >
                <div className="ota-graphics">
                  <div className="ota-card dtype-count">
                    <div>
                      <strong>{snmpDevices.length}</strong>
                      <span className="snmp">SNMP</span>
                    </div>
                    <div>
                      <strong>{httpDevices.length}</strong>
                      <span className="https">HTTPS</span>
                    </div>
                    <div>
                      <strong>0</strong>
                      <span className="mqtt">MQTT</span>
                    </div>
                    <div>
                      <strong>0</strong>
                      <span className="tr069">TR-069</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="sc">
                        <img src={OTAs} alt="Device Schedule" />
                      </span>
                    </div>
                    <div>
                      <strong>{scheduledDevices}</strong>
                      <span>Device Schedule</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="upi">
                        <img src={OTAupi} alt="Device Updating" />
                      </span>
                    </div>
                    <div>
                      <strong>{pendingDevices}</strong>
                      <span>Device Pending</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="fl">
                        <img src={OTAf} alt="Device Failed" />
                      </span>
                    </div>
                    <div>
                      <strong>0</strong>
                      <span>Device Failed</span>
                    </div>
                  </div>

                  <div className="ota-card ota-count">
                    <div>
                      <span className="upd">
                        <img src={OTAupd} alt="Device Updated" />
                      </span>
                    </div>
                    <div>
                      <strong>{updatedDevices}</strong>
                      <span>Up-to-date</span>
                    </div>
                  </div>
                </div>

                <div className="ota-filter">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <select className="form-select">
                      <option>Any Device</option>
                      <option>Device 01</option>
                    </select>
                    <select className="form-select">
                      <option>Any Status</option>
                      <option>Status 01</option>
                    </select>
                  </div>
                  <div>
                    <button className="text-btn primary-btn">Updates</button>
                    <button className="text-btn primary-btn" onClick={()=>setScheduleModel(true)}>Schedule</button>
                  </div>
                </div>
                <div className="inc-card-table">
                  <table className="full-table m-head">
                    <thead>
                      <tr>
                        <th>Device Name</th>
                        <th>Model</th>
                        <th>Protocol Type</th>
                        <th>Current Firmware</th>
                        <th>Available Firmware</th>
                        <th>Update Status</th>
                        <th>Next Steps</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    {loading ? (
                      <Loader />
                    ) : (
                      <tbody>
                        {currentListings.map((device, index) => (
                          <tr key={index}>
                            <td>{device.deviceName || "N/A"}</td>
                            <td>{device.deviceModel || "N/A"}</td>
                            <td>{(device.protocol || "N/A").toUpperCase()}</td>
                            <td>{device.firmwareCurrentVersion || "N/A"}</td>
                            <td>
                              {device.protocol === "SNMP" ? (
                                <select
                                  className="form-select"
                                  style={{ width: "100px" }}
                                  value={device.firmware_id || "N/A"}
                                  // onChange={(e) =>
                                  //   handleVersionChange(
                                  //     device.deviceId,
                                  //     e.target.value
                                  //   )
                                  // }
                                  onFocus={() =>
                                    !firmwareVersions[device.deviceModel] &&
                                    fetchFirmwareVersions(device.deviceModel)
                                  }
                                >
                                  <option value="N/A" disabled>
                                    {device.firmwareNewVersion || "N/A"}
                                  </option>
                                  {firmwareVersions[device.deviceModel]?.map(
                                    (version) => (
                                      <option
                                        key={version.firmwareId}
                                        value={version.firmwareId}
                                      >
                                        {version.firmwareVersion}
                                      </option>
                                    )
                                  )}
                                </select>
                              ) : (
                                device.firmwareNewVersion || "N/A"
                              )}
                            </td>

                            <td>{device.updateStatus || "N/A"}</td>
                            <td>
                              {device.nextStep === "Update Now" ? (
                                <button
                                  className="text-btn primary-btn"
                                  title="Update Now"
                                  onClick={() => handleUpdateClick(device)}
                                >
                                  Update Now
                                </button>
                              ) : (
                                <button
                                  className="text-btn primary-btn"
                                  disabled
                                >
                                  Update Now
                                </button>
                              )}
                            </td>
                            {/* <td>
                              <button
                                className="icon-btn"
                                title="Download"
                                onClick={() =>
                                  handleDownloadClick(device.remoteFilePath)
                                }
                              >
                                <i
                                  className="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="pagination-container">
                  <div className="pagination-info">
                    {totalFilteredListings > 0 ? (
                    <p>
                      Showing {indexOfFirstListing + 1}
                      {"-"}
                      {Math.min(
                        currentListPage * listingsPerPage,
                        totalFilteredListings
                      )} of {totalFilteredListings} records
                    </p>
                    ):(
                      <p>No Records Available</p>
                    )}
                  </div>
                  <div className="pagination">
                    <button onClick={handleListPrevPage} disabled={currentListPage === 1}>
                      Previous
                    </button>
                    {getPageNum().map((page) => (
                      <button
                        key={page}
                        onClick={() => handleListPageClick(page)}
                        className={currentListPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}
                    <button
                      onClick={handleListNextPage}
                      disabled={currentListPage === totalListPage}
                    >
                      Next
                    </button>
                    <button
                      onClick={handleListLastPage}
                      disabled={currentListPage === totalListPage}
                    >
                      Last Page
                    </button>
                  </div>
                </div>

                {/* Schedule Modal */}
                {scheduleModel && (
                <div className="customModal">
                  <div className="c-modal-dialog modal-dialog-centered small">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Firmware Schedule</h5>
                        <button type="button" className="btn-close" onClick={()=>setScheduleModel(false)}></button>
                      </div>
                      <div className="modal-body add-ssid-body">
                        
                        <div className="row mb-2">
                          <div className="col-lg-6">
                            <label htmlFor="ProtocolType" className="col-form-label">Protocol Type</label>
                            <select className="form-select">
                              <option>SNMP</option>
                              <option>HTTP</option>
                              <option>MQTT</option>
                            </select>
                          </div>
                        </div>
                        <div className="border-line"></div>
                        <div className="row mb-2">
                          <div className="col-lg-6">
                            <label htmlFor="DeviceType" className="col-form-label">Device Type</label>
                            <input type="text" className="form-control" name="DeviceType" placeholder="Device Type" />
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="DeviceFamily" className="col-form-label">Device Family</label>
                            <input type="text" className="form-control" name="DeviceFamily" placeholder="Device Family" />
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="Device" className="col-form-label">Device</label>
                            <input type="text" className="form-control" name="Device" placeholder="Device" />
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="Select Version" className="col-form-label">Select Version</label>
                            <select className="form-select">
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </select>
                          </div>                          
                        </div>
                        <div className="row mb-3 mt-4">
                          <div className="col-lg-6 mt-2">
                            <div className="tab-form">
                              <div className="switch-box">
                                <strong>Firmware Schedule</strong>
                                <label className="ice-switch">
                                  <input type="checkbox" />
                                  <span className="slider"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-lg-6">
                                <input type="text" className="form-control" name="Hour" placeholder="Hour" />
                              </div>
                              <div className="col-lg-6">
                                <input type="text" className="form-control" name="Minute" placeholder="Minute" />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-outline" onClick={()=>setScheduleModel(false)}>
                          Cancel
                        </button>                        
                        <button type="button" className="text-btn primary-btn">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                )}

              </div>
              <div
                className="tab-pane fade"
                id="firmware"
                role="tabpanel"
                aria-labelledby="firmware-tab"
              >
                <div className="ota-filter">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <select className="form-select">
                      <option>Any Device</option>
                      <option>Device 01</option>
                    </select>
                    <select className="form-select">
                      <option>Any Status</option>
                      <option>Status 01</option>
                    </select>
                  </div>
                  <div>
                    <button
                      className="text-btn primary-btn"
                      onClick={handleAddFirmwareClick}
                    >
                      Add Firmware
                    </button>
                  </div>
                </div>

                {/* ===============Add FIRMWARE---------- */}
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}

                {isModalOpen && (
                  <div className="customModal">
                    <div className="c-modal-dialog modal-dialog-centered small">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Add Firmware</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handleCancel}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="add-ssid-body">
                            <form>
                              <div className="row d-flex">
                                <div className="col-lg-6">
                                  <label
                                    htmlFor="protocolType"
                                    className="col-form-label"
                                  >
                                    Protocol Type
                                  </label>
                                  <select
                                    className="form-select"
                                    name="protocolType"
                                    value={firmwareFields.protocolType}
                                    onChange={handleProtocolChange}
                                  >
                                    <option value="">
                                      Select Protocol Type
                                    </option>
                                    <option value="HTTP">HTTP</option>
                                    <option value="SNMP">SNMP</option>
                                  </select>
                                </div>

                                {/* HTTP-specific fields */}
                                {firmwareFields.protocolType === "HTTP" && (
                                  <>
                                    {/* Device Type for HTTP */}
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="deviceType"
                                        className="col-form-label"
                                      >
                                        Device Type
                                      </label>
                                      <select
                                        className="form-select"
                                        name="deviceType"
                                        value={firmwareFields.deviceType}
                                        onChange={handleModelChange}
                                      >
                                        <option value="">
                                          Select Device Type
                                        </option>
                                        <option value="AP">AP</option>
                                        <option value="Switch">Switch</option>
                                        <option value="Router">Router</option>
                                      </select>
                                    </div>

                                    {/* Model Name for HTTP */}
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="modelName"
                                        className="col-form-label"
                                      >
                                        Model Name
                                      </label>
                                      <select
                                        className="form-select"
                                        name="modelName"
                                        value={firmwareFields.modelName}
                                        onChange={handleModelChange}
                                      >
                                        <option value="">Select Model</option>
                                        {models.map((model, index) => (
                                          <option
                                            key={index}
                                            value={model.value}
                                          >
                                            {model.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </>
                                )}

                                {/* Common fields for both SNMP and HTTP */}
                                {(firmwareFields.protocolType === "SNMP" ||
                                  firmwareFields.protocolType === "HTTP") && (
                                    <>
                                      {/* Model Family */}
                                      {/* <div className="col-lg-6">
                                      <label
                                        htmlFor="modelFamily"
                                        className="col-form-label"
                                      >
                                        Model Family
                                      </label>
                                      <select
                                        className="form-select"
                                        name="modelFamily"
                                        value={firmwareFields.modelFamily}
                                        onChange={handlesnmpFamily}
                                      >
                                        <option value="">Select Family</option>
                                        {snmpFamilies.map((family, index) => (
                                          <option key={index} value={family}>
                                            {family}
                                          </option>
                                        ))}
                                      </select>
                                    </div> */}

                                      {/* Version Name (only for HTTP) */}
                                      {firmwareFields.protocolType === "HTTP" && (
                                        <div className="col-lg-6">
                                          <label
                                            htmlFor="version"
                                            className="col-form-label"
                                          >
                                            Version Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="version"
                                            value={firmwareFields.version}
                                            onChange={handleInputChange}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}

                                {/* SNMP-specific Model Name */}
                                {firmwareFields.protocolType === "SNMP" && (
                                  <>
                                    <div className="col-lg-6">
                                      <label htmlFor="modelName" className="col-form-label">
                                        Device Type
                                      </label>
                                      <select
                                        className="form-select"
                                        name="deviceType"
                                        value={firmwareFields.deviceType}
                                        onChange={handleDeviceType}
                                      >
                                        <option value="">Select Model</option>
                                        {/* Map over the snmpDeviceType array to create options */}
                                        {snmpDeviceType.length > 0 ? (
                                          snmpDeviceType.map((deviceType, index) => (
                                            <option key={index} value={deviceType}>
                                              {deviceType}
                                            </option>
                                          ))
                                        ) : (
                                          <option value="">No device types available</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="modelName"
                                        className="col-form-label"
                                      >
                                        Model Name
                                      </label>
                                      <select
                                        className="form-select"
                                        name="modelName"
                                        value={firmwareFields.modelName}
                                        onChange={handleInputChange}
                                      >
                                        <option value="">Select Model</option>
                                        <option value="ion4l3s_d">ion4l3s_d</option>
                                        <option value="ion4l3j">ion4l3j</option>
                                        <option value="ion4l3s_d">ion4l3s_d</option>
                                        <option value="ion4l3j">ion4l3j</option>
                                        <option value="ion4l3_ext">ion4l3_ext </option>
                                      </select>
                                    </div>
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="version"
                                        className="col-form-label"
                                      >
                                        Version Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="version"
                                        value={firmwareFields.version}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </>
                                )}

                                {/* Choose Firmware File */}
                                <div className="col-lg-6">
                                  <label
                                    htmlFor="formFile"
                                    className="form-label"
                                  >
                                    Choose Firmware File
                                  </label>
                                  <input
                                    className="form-control"
                                    type="file"
                                    onChange={handleFileChange}
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-outline"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="text-btn primary-btn"
                            title="Upload"
                            onClick={addFirmware}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="inc-card-table">
                  <table className="full-table m-head">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Version</th>
                        <th>Model</th>
                        <th>Device Type</th>
                        <th>Release Date</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <Loader />
                    ) : (
                      <tbody>
                        {currentDetails.map((detail, index) => (
                          <tr key={index}>
                            <td>{detail.firmwareName || "N/A"}</td>
                            <td>{detail.firmwareVersion || "N/A"}</td>
                            <td>{detail.modelName || "N/A"}</td>
                            <td>{detail.deviceType || "N/A"}</td>
                            <td>{detail.firmwareReleaseDate || "N/A"}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}

                  </table>

                </div>
                <div className="pagination-container">
                  <div className="pagination-info">
                    {totalFilteredFirmwares > 0 ? (
                    <p>
                      Showing {indexOfFirstFirmwares + 1}
                      {"-"}
                      {Math.min(
                        currentPage * firmwaresPerPage,
                        totalFilteredFirmwares
                      )} of {totalFilteredFirmwares} records
                    </p>
                    ):(
                      <p>No Records Available</p>
                    )}
                  </div>
                  <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                      Previous
                    </button>
                    {getPageNumbers().map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      onClick={handleLastPage}
                      disabled={currentPage === totalPages}
                    >
                      Last Page
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="scheduled"
                role="tabpanel"
                aria-labelledby="scheduled-tab"
              >
                <div className="tab-pagination">
                  <div className="inc-card-table">
                    <table className="full-table m-head">
                      <thead>
                        <tr>
                          <th>Model</th>
                          <th>Serial</th>
                          <th>MAC</th>
                          <th>Current Firmware</th>
                          <th>New Firmware</th>
                          <th>Update Status</th>
                          <th>Next Steps</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-container">
                    <div className="pagination-info"></div>
                    <div className="pagination">
                      <button>Previous</button>
                      <button>Next</button>
                      <button>Last Page</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      {/* <div
        className={`modal fade`}
        id="successModel"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={successIcon} alt="Success" />
              <p>Firmware updated successfully</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => overviewDetails()}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        className={`modal fade`}
        id="successAddModel"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={successIcon} alt="Success" />
              <p>Firmware added successfully</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => firmwareDetails()}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {showFailurePopup && (
        <div className="customModal confirm-modal">
          <div className="modal-dialog modal-dialog-centered small">
            <div className="modal-content inc-modal">
              <div className="modal-body">
                <img src={errorIcon} alt="error" />
                <h3>Firmware Upgrade failed.</h3>
                <div className="modal-footer">
                  <button className="text-btn primary-btn" onClick={() => setShowFailurePopup(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFirmwareFailurePopup && (
        <div className="customModal confirm-modal">
          <div className="modal-dialog modal-dialog-centered small">
            <div className="modal-content inc-modal">
              <div className="modal-body">
                <img src={errorIcon} alt="error" />
                <h3>Firmware Upload failed.</h3>
                <div className="modal-footer">
                  <button className="text-btn primary-btn" onClick={() => setShowFirmwareFailurePopup(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showSuccessModal}
        onHide={()=>setShowSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Firmware upgraded successfully</p>
        <Button onClick={() => {
          setShowSuccessModal(false);
          overviewDetails();          
          }}>
          OK
        </Button>
      </Modal>
      <Modal
        show={showSuccessAddModal}
        onHide={()=>setShowSuccessAddModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Firmware uploaded successfully</p>
        <Button onClick={() => {
          setShowSuccessAddModal(false);
          firmwareDetails();
        }}>
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default OTAManagement;
