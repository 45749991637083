import React from "react";

function Reports() {
  return (
    <div>
      <h4>Rearrange Widgets</h4>
      <p>
        Arrange the widgets by dragging and dropping them in the order in which
        you want them to appear.
      </p>

      <div className="accordion mt-4" id="accordionRearrange">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Widgets
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionRearrange"
          >
            <div className="accordion-body">
              <div className="inc-card-table">
                <table className="m-head">
                  <thead>
                    <tr>
                      <th>Widgets Name</th>
                      <th>Descriptions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Network Growth</td>
                      <td>
                        Lorem Ipsum has been the industry's standard dummy text
                      </td>
                    </tr>
                    <tr>
                      <td>Network Growth</td>
                      <td>
                        Lorem Ipsum has been the industry's standard dummy text
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
