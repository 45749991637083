import React from "react";

function vlanManagement() {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-6">
          <label className="col-form-label">Management VLAN</label>
          <select className="form-select">
            <option>Management VLAN</option>
            <option>Video VLAN (4089)</option>
            <option>vlan16 (16)</option>
            <option>Custom Management VLAN (2545)</option>
          </select>
        </div>
      </div>
      <div className="edit-custom-link">
        <a href="#">
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
          Edit Custom VLAN
        </a>
      </div>


      <div className="row mb-3 mt-4">
        <div className="col-lg-6">          
          <label className="col-form-label label-check">
            Untagged VLAN
            <label className="checkbox">
                <input type="checkbox" />
                <span className="indicator"></span>
              </label>
          </label>     
          <select className="form-select">
            <option>Management VLAN</option>
            <option>Video VLAN (4089)</option>
            <option>vlan16 (16)</option>
            <option>Custom Management VLAN (2545)</option>
          </select>
        </div>
      </div>
      <div className="edit-custom-link">
        <a href="#">
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
          Edit Custom VLAN
        </a>
      </div>


      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">Cancel</button>
        <button type="submit" className="text-btn primary-btn">Save</button>
      </div>


      {/* <div className="customModal">
        <div className="c-modal-dialog modal-dialog-centered small">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add VLAN</h5>
              <button type="button" className="btn-close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">VLAN Name</label>
                <input type="text" className="form-control" placeholder="www.example.com" />
              </div>
              <div className="mb-3">
                <label className="form-label">VLAN ID</label>
                <input type="text" className="form-control" placeholder="www.example.com" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline">Cancel</button>
              <button type="submit" className="text-btn primary-btn">Save</button>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default vlanManagement;
